import React from "react";
import { graphql } from "gatsby";
import Seo from "react-seo-component";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { getImage } from "gatsby-plugin-image";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { Layout, Image } from "../components";
import { Container, Title } from "../components/styles";

export default function SingleBlogPostPage({ data, location }) {
  const {
    frontmatter: { title, slug, featureImage },
    body,
    excerpt,
  } = data.post;

  const featuredImage = featureImage
    ? getImage(featureImage.childImageSharp.gatsbyImageData)
    : null;

  const { pathname } = location;

  const showTemplate = false;

  const {
    title: siteTitle,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
    authorName,
  } = useSiteMetadata();

  return (
    <Layout pathname={pathname}>
      <Seo
        title={title}
        titleTemplate={siteTitle}
        description={excerpt}
        pathname={`${siteUrl}${slug}`}
        article={true}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
        author={authorName}
        modifiedDate={new Date(Date.now()).toISOString()}
      />

      {showTemplate && <p>This is using src/templates/BlogPost.js</p>}

      {featuredImage && (
        <Image image={featuredImage} type="featured" alt="feature" />
      )}
      <Title>{title}</Title>
      <Container>
        <MDXRenderer>{body}</MDXRenderer>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query SingleBlogPostQuery($id: String!) {
    post: mdx(id: { eq: $id }) {
      frontmatter {
        title
        slug
        featureImage {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: FULL_WIDTH
              formats: [AUTO, WEBP]
              transformOptions: { fit: COVER, cropFocus: ATTENTION }
            )
          }
        }
      }
      body
      excerpt
    }
  }
`;
